<template>
    <div class="intro-y box p-5 col-span-4 lg:col-span-6 sm:col-span-12">
        <form class="flex flex-col gap-4" @submit.prevent="updateSalesBanner">
            <label for="sale-text" class="form-label">Акция на раннее бронирование</label>
            <input
                id="sale-text"
                v-model="saleText"
                type="text"
                class="form-control w-full"
                placeholder=""
                autocomplete="off"
            />

            <button type="button" class="btn btn-primary w-full" @click="updateSalesBanner">Сохранить</button>
        </form>
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';

export default {
    name: 'SaleTextSetting',
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            saleText: '',
        };
    },
    async created() {
        this.loading = true;
        await this.fetchSaleBanner();
        this.loading = false;
    },
    methods: {
        async fetchSaleBanner() {
            try {
                const { data } = await this.axios.get('/sales-banner');

                if (data.success) {
                    this.saleText = data.data;
                } else {
                    this.$notify({ text: data.message || 'Произошла ошибка :(', type: 'error' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        async updateSalesBanner() {
            this.loading = true;

            try {
                const { data } = await this.axios.post('/sales-banner', { value: this.saleText });

                if (data.success) {
                    this.$notify({ text: data.message || 'Изменения сохранены', type: 'success' });
                } else {
                    this.$notify({ text: data.message || 'Произошла ошибка :(', type: 'error' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }

            this.loading = false;
        },
    },
};
</script>

<style scoped lang="scss"></style>
