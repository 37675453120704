<template>
    <div class="intro-y box p-5 col-span-4 lg:col-span-6 sm:col-span-12">
        <form class="flex flex-col gap-4" @submit.prevent="updateHiddenTagOnMainPage">
            <label for="hidden_tag" class="form-label">Скрытые тэги для круизов на главной странице</label>

            <Multiselect
                id="hidden_tag"
                v-model="hidden_tag"
                :options="hidden_tag"
                mode="tags"
                placeholder="SEO тэги для каждого круиза на главной странице"
                :hide-selected="false"
                :close-on-select="false"
                :native="false"
                :searchable="true"
                :create-option="true"
                no-results-text="Введите новый тэг и Enter чтобы создать"
                no-options-text="Введите новый тэг и Enter чтобы создать"
            />

            <button type="button" class="btn btn-primary w-full" @click="updateHiddenTagOnMainPage">Сохранить</button>
        </form>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import { errorResponse } from '@/mixins/form/form-mixin';

export default {
    name: 'HiddenTagMainPageSetting',
    components: { Multiselect },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            hidden_tag: null,
        };
    },
    async created() {
        this.loading = true;
        await this.fetchHiddenTagMainPage();
        this.loading = false;
    },
    methods: {
        async fetchHiddenTagMainPage() {
            try {
                const { data } = await this.axios.get('/hidden-tag');

                if (data.success) {
                    this.hidden_tag = data.data;
                } else {
                    this.$notify({ text: data.message || 'Произошла ошибка :(', type: 'error' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        async updateHiddenTagOnMainPage() {
            this.loading = true;

            try {
                const { data } = await this.axios.post('/hidden-tag', { value: this.hidden_tag });

                if (data.success) {
                    this.$notify({ text: data.message || 'Изменения сохранены', type: 'success' });
                } else {
                    this.$notify({ text: data.message || 'Произошла ошибка :(', type: 'error' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }

            this.loading = false;
        },
    },
};
</script>

<style scoped lang="scss"></style>
